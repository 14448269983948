import React, { useEffect } from "react"; // Import 'react' library and 'useEffect' hook
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import routing components from 'react-router-dom'
import "./styles/global/uni.scss"; // Import global styles
import LoadingExport from "./components/global/Loading"; // Import 'LoadingExport' component
const NoPage = React.lazy(() => import("./components/global/NoPage")); // Import 'NoPage' component
const LayoutExport = React.lazy(() => import("./components/global/Layout")); // Lazy-load 'LayoutExport' component
const LoadingSub = React.lazy(() => import("./components/global/LoadingSub")); // Lazy-load 'LoadingSub' component
const Downloads = React.lazy(() => import("./components/pages/downloads/Downloads")); // Lazy-load 'Downloads' component
const Home = React.lazy(() => import("./components/pages/home/Home")); // Lazy-load 'Home' component
const Application = React.lazy(() => import("./components/pages/application/Application")); // Lazy-load 'Application' component
const Media = React.lazy(() => import("./components/pages/media/Media")); // Lazy-load 'Media' component
const PrivacyPolicy = React.lazy(() => import("./components/pages/privacy-policy/PrivacyPolicy")); // Lazy-load 'PrivacyPolicy' component

function App() {
  useEffect(() => {
    // useEffect hook to run the following code after the component is mounted
    const noscriptElement = document.getElementById("noscript"); // Get the DOM element with id 'noscript'
    if (noscriptElement) {
      noscriptElement.remove(); // Remove the 'noscript' element from the DOM if it exists
    }
    console.clear(); // Clear the console on component mount
  }, []);

  return (
    <Router>
      {" "}
      {/* BrowserRouter as the main Router */}
      <React.Suspense fallback={<LoadingExport />}>
        {" "}
        {/* A fallback loading component to show when lazy-loading components */}
        <Routes>
          {" "}
          {/* Set up the routing */}
          <Route path="/" element={<LayoutExport />}>
            {" "}
            {/* Default route for the home page */}
            <Route index element={<Home />} />{" "}
            {/* Index route for the home page */}
            <Route path="application" element={<Application />} />{" "}
            {/* Route for the 'Application' page */}
            <Route path="media" element={<Media />} />{" "}
            {/* Route for the 'Media' page */}
            <Route path="privacy-policy" element={<PrivacyPolicy />} />{" "}
            {/* Route for the 'PrivacyPolicy' page */}
            {/* The following route is not necessary for website functionality */}
            <Route path="downloads" element={<Downloads />} />{" "}
            {/* Route for the 'Downloads' page */}
            <Route path="*" element={<NoPage />} />{" "}
            {/* Wildcard route for any other path */}
          </Route>
          <Route path="loading" element={<LoadingExport />} />{" "}
          {/* Route for the 'LoadingExport' page */}
          <Route path="loadingsub" element={<LoadingSub />} />{" "}
          {/* Route for the 'LoadingSub' page */}
          <Route path="*" element={<NoPage />} />{" "}
          {/* Wildcard route for any other path */}
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App; // Export the 'App' component as the default
