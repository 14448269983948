import React from 'react'; // Import the 'react' library
import ReactDOM from 'react-dom'; // Import the 'react-dom' library for rendering
import App from './App'; // Import the 'App' component from './App' file

const rootElement = document.getElementById('root'); // Get the DOM element with id 'root' to be the root container for rendering the app

// Define a function to render the 'App' component into the root element
const renderApp = () => {
  // Create the 'app' JSX element, and conditionally wrap it with 'StrictMode' only in development mode
  const app = (
    process.env.NODE_ENV === 'development' ? ( // Check if the environment is development
      <React.StrictMode>
        <App /> {/* Render the 'App' component */}
      </React.StrictMode>
    ) : (
      /* Render the 'App' component without 'StrictMode' in production */
      <App />
    )
  );

  ReactDOM.render(app, rootElement); // Render the 'app' JSX element into the root element
};

renderApp(); // Call the 'renderApp' function to start rendering the app
