// Import required modules and components
import React from "react";
import { Outlet, Link } from "react-router-dom";
import { headerData } from "../../data/global/header";

// Header component contains the logo and navigation links
const Header = () => {
  // Function to handle the click event and navigate to the home page
  const sendHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="header">
      {/* Logo button */}
      <button
        onClick={sendHome}
        style={{ zIndex: "99" }}
        className="header__logo"
        aria-label="Logo and Home Button"
        title="Logo home button"
        name="header logo link to home"
      ></button>
      {/* First set of navigation links */}
      <ul className="header__nav header__nav_1">
        {/* Mapping through headerData to create the links */}
        {headerData.map(({ link, text }) => (
          <li className="nav__link" key={link}>
            <a href={link} className="link__a" aria-label={`Link to ${text}`}>
              {text}
            </a>
          </li>
        ))}
      </ul>
      {/* Second set of navigation links */}
      <ul className="header__nav header__nav_2">
        <li className="nav__link">
          {/* Link to the application page */}
          <Link
            to={headerData[1].link}
            className="material-symbols-outlined link__a"
            aria-label={`Link to ${headerData[1].text} Page`}
          >
            {/* Icon for the application link */}
            {headerData[1].icon}
            {/* Text for the application link */}
            <span>{headerData[1].text}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

// Layout component contains the Header component and the Outlet for rendering other components
const Layout = () => (
  <>
    {/* Render the Header component */}
    <Header />
    <div className="container">
      {/* Outlet for rendering other components based on the route */}
      <Outlet />
    </div>
  </>
);

// Loading component shows a loading animation while content is being fetched
const Loading = () => (
  <>
    <div className="container__loading">
      {/* SVG animation for loading */}
      <svg
        className="pl"
        viewBox="0 0 200 200"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
            <stop offset="0%" stopColor="hsl(102,88%,61%)" />
            <stop offset="100%" stopColor="hsl(135,93%,32%)" />
          </linearGradient>
          <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="hsl(102,88%,61%)" />
            <stop offset="100%" stopColor="hsl(135,93%,32%)" />
          </linearGradient>
        </defs>
        <circle
          className="pl__ring"
          cx="100"
          cy="100"
          r="82"
          fill="none"
          stroke="url(#pl-grad1)"
          strokeWidth="36"
          strokeDasharray="0 257 1 257"
          strokeDashoffset="0.01"
          strokeLinecap="round"
          transform="rotate(-90,100,100)"
        />
        <line
          className="pl__ball"
          stroke="url(#pl-grad2)"
          x1="100"
          y1="18"
          x2="100.01"
          y2="182"
          strokeWidth="36"
          strokeDasharray="1 165"
          strokeLinecap="round"
        />
      </svg>
    </div>
  </>
);

// Export the Layout and Loading components
export { Layout, Loading };
